<script setup lang="ts">
import VanillaTilt from 'vanilla-tilt';

const config = useRuntimeConfig();

// const { data: card } = await useFetch('/api/cards')
const { data: mostExpensiveCard } = await useFetch('/api/cards/stats/most-expensives')
const { data: lessExpensiveCard } = await useFetch('/api/cards/stats/less-expensives')
const { data: mostSoldCard } = await useFetch('/api/cards/stats/most-sold')
const { data: adsStats } = await useFetch('/api/ads/stats')
const { data: rareCards } = await useFetch('/api/cards/stats/rare-cards')

const element = ref(null)

onMounted(() => {
  VanillaTilt.init(
    element.value,
    {
      max: 10,
      speed: 400,
      scale: 1.025,
      reverse: true,
      glare: true,
      "max-glare": 0.5,
      gyroscope: false,
    }
  )
})
</script>

<script lang="ts">
export default {
  data: () => {
    return {
      selectedCard: 1
    }
  },
  methods: {
    changeSelectedCard(direction: number) {
      this.selectedCard += direction
    }
  }
}
</script>

<template>
    <div class="flex flex-col p-4 md:p-10 pt-0 md:pt-0 gap-4 md:gap-8">
        <div class="flex flex-col lg:flex-row gap-4 md:gap-8 flex-1">
            <div
                class="lg:w-2/5 shadow-lg bg-cool-200 dark:bg-cool-600 rounded-3xl p-4 md:p-12 flex flex-col justify-between">
                <div class="flex flex-col gap-4 md:gap-8">
                    <div class="text-xl md:text-4xl font-yellowtail">
                        Wankul Market
                    </div>
                    <div class="text-3xl md:text-6xl font-extrabold uppercase">
                        Suivez le marché des cartes Wankul
                    </div>
                    <div class="text-lg">
                        <p>Wankul Market est le site de référence pour suivre l'évolution du prix des
                            <ULink to="https://wankul.fr/" target="_blank" active-class="text-primary"
                                inactive-class="text-wankil_blue-500 dark:text-wankil_blue-100 dark:text-gray-400 hover:text-wankil_blue-100 dark:hover:text-wankil_blue-500">
                                cartes Wankul
                            </ULink>sur le marché de l'occasion.
                            Vous cherchez à acheter une carte ? Recherchez là sur le site et parcourez les annonces
                            <ULink to="https://vinted.fr" target="_blank" active-class="text-primary"
                                inactive-class="text-wankil_blue-500 dark:text-wankil_blue-100 dark:text-gray-400 hover:text-wankil_blue-100 dark:hover:text-wankil_blue-500">
                                Vinted
                            </ULink>
                            vendant cette carte !
                            Elle vous paraît trop chère ? Constatez son prix moyen et négociez avec le vendeur pour en
                            avoir un
                            meilleur prix.
                        </p>
                    </div>
                    <UButton size="xl" icon="i-heroicons-arrow-right" to="/cards" :prefetch="true"
                        class="bg-wankil_blue-500 dark:bg-wankil_blue-100 w-fit hover:bg-wankil_blue-100 dark:hover:bg-wankil_blue-500 text-white"
                        :ui="{ rounded: 'rounded-full' }" trailing>Parcourir les cartes</UButton>
                </div>
                <div class="italic text-sm mt-4 md:mt-0">
                    <p>
                        J'ai créé cette application pour la communauté suite à la vidéo du vol de carte. En espérant
                        qu'elle vous
                        sera utile. Quentin aka MadPenguin.
                    </p>
                </div>
            </div>
            <div
                class="right-block relative shadow-lg flex items-center p-4 md:p-12 lg:w-3/5 bg-cool-200 dark:bg-cool-600 rounded-3xl">
                <UButton :disabled="selectedCard == 1" @click="changeSelectedCard(-1)"
                    class="previous-button p-2 rounded-xl bg-wankil_blue-500 text-white flex items-center absolute left-3 bottom-3 cursor-pointer">
                    <UIcon name="i-heroicons-chevron-left" />
                </UButton>
                <UButton :disabled="selectedCard == 3" @click="changeSelectedCard(1)"
                    class="next-button p-2 rounded-xl bg-wankil_blue-500 text-white flex items-center absolute right-3 bottom-3 cursor-pointer">
                    <UIcon name="i-heroicons-chevron-right" />
                </UButton>
                <TransitionGroup name="slide-fade">
                    <div class="flex flex-col md:flex-row w-full items-center h-full" v-if="selectedCard == 1">
                        <div class="uppercase w-full text-3xl font-extrabold md:hidden">
                            Carte la plus chère <span class="text-sm"></span>
                        </div>
                        <div class="flex justify-center w-full md:w-1/2">
                            <ULink class="flex justify-center" :to="`/cards/${mostExpensiveCard.id}`">
                                <NuxtImg class="rounded-xl md:w-80 shadow-2xl my-4 md:my-8"
                                    :src="`${config.public.basePath}/api/media/wankuldex/${mostExpensiveCard.imageId}`"
                                    alt="Carte la plus chère" srcset="" />
                            </ULink>
                        </div>
                        <div class="flex flex-col h-full w-full md:w-1/2 items-end gap-4 justify-between p-2">
                            <div class="flex flex-col items-end gap-4">
                                <div class="uppercase text-xl md:text-4xl font-semibold text-end hidden md:block">
                                    Carte la plus chère <span class="text-sm"></span>
                                </div>
                                <div class="text-end">
                                    <span class="text-3xl md:text-6xl font-extrabold">{{ mostExpensiveCard.title
                                        || "N/A" }}</span>
                                    <span class="artist text-3xl font-yellowtail artist-gradient px-2">{{
                                        mostExpensiveCard.artist }}</span>
                                </div>
                                <div>
                                    <UBadge
                                        class="text-wankil_blue-500 ring-wankil_blue-500 bg-wankil_blue-100/10 dark:bg-wankil_blue-100/10 dark:text-wankil_blue-100 dark:ring-wankil_blue-100"
                                        size="lg" variant="subtle">{{ mostExpensiveCard.season || "N/A" }}
                                    </UBadge>
                                </div>
                                <div class="text-xl md:text-4xl">
                                    {{ mostExpensiveCard.mediumPrice.toFixed(2) || "N/A" }} €
                                </div>
                            </div>
                            <div class="flex justify-between gap-4">
                                <div v-if="mostExpensiveCard?.adNumber > 1">
                                    {{ mostExpensiveCard?.adNumber }} annonces entre {{ mostExpensiveCard?.minPrice }}€
                                    et {{
                                    mostExpensiveCard.maxPrice
                                    }}€
                                </div>
                                <div v-else>
                                    1 annonce à {{ mostExpensiveCard?.minPrice }}€
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row w-full items-center h-full" v-if="selectedCard == 2">
                        <div class="uppercase w-full text-3xl font-extrabold md:hidden">
                            Carte la plus revendu <span class="text-sm"></span>
                        </div>
                        <div class="flex justify-center w-full md:w-1/2">
                            <ULink class="flex justify-center" :to="`/cards/${mostSoldCard.id}`">
                                <NuxtImg class="rounded-xl md:w-80 shadow-2xl my-4 md:my-8"
                                    :src="`${config.public.basePath}/api/media/wankuldex/${mostSoldCard.imageId}`"
                                    alt="Carte la plus revendu" srcset="" />
                            </ULink>
                        </div>
                        <div class="flex flex-col h-full w-full md:w-1/2 items-end gap-4 justify-between p-2">
                            <div class="flex flex-col items-end gap-4">
                                <div class="uppercase text-xl md:text-4xl font-semibold text-end hidden md:block">
                                    Carte la plus revendu <span class="text-sm"></span>
                                </div>
                                <div class="text-end">
                                    <span class="text-3xl md:text-6xl font-extrabold">{{ mostSoldCard.title ||
                                        "N/A" }}</span>
                                    <span class="artist text-3xl font-yellowtail artist-gradient px-2">{{
                                        mostSoldCard.artist }}</span>
                                </div>
                                <div>
                                    <UBadge
                                        class="text-wankil_blue-500 ring-wankil_blue-500 bg-wankil_blue-100/10 dark:bg-wankil_blue-100/10 dark:text-wankil_blue-100 dark:ring-wankil_blue-100"
                                        size="lg" variant="subtle">{{ mostSoldCard.season || "N/A" }}</UBadge>
                                </div>
                                <div class="text-xl md:text-4xl">
                                    {{ mostSoldCard.mediumPrice?.toFixed(2) || "N/A" }} €
                                </div>
                            </div>
                            <div class="flex justify-between gap-4">
                                <div v-if="mostSoldCard?.adNumber > 1">
                                    {{ mostSoldCard?.adNumber }} annonces entre {{ mostSoldCard?.minPrice }}€
                                    et {{
                                    mostSoldCard.maxPrice
                                    }}€
                                </div>
                                <div v-else>
                                    1 annonce à {{ mostSoldCard?.minPrice }}€
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row w-full items-center h-full" v-if="selectedCard == 3">
                        <div class="uppercase w-full text-3xl font-extrabold md:hidden">
                            Carte la moins chère <span class="text-sm"></span>
                        </div>
                        <div class="flex justify-center w-full md:w-1/2">
                            <ULink class="flex justify-center" :to="`/cards/${lessExpensiveCard.id}`">
                                <NuxtImg class="rounded-xl md:w-80 shadow-2xl my-4 md:my-8"
                                    :src="`${config.public.basePath}/api/media/wankuldex/${lessExpensiveCard.imageId}`"
                                    alt="Carte la moins chère" srcset="" />
                            </ULink>
                        </div>
                        <div class="flex flex-col h-full w-full md:w-1/2 items-end gap-4 justify-between p-2">
                            <div class="flex flex-col items-end gap-4">
                                <div class="uppercase text-xl md:text-4xl font-semibold text-end hidden md:block">
                                    Carte la moins chère <span class="text-sm"></span>
                                </div>
                                <div class="text-end">
                                    <span class="text-3xl md:text-6xl font-extrabold">{{ lessExpensiveCard.title
                                        || "N/A" }}</span>
                                    <span class="artist text-3xl font-yellowtail artist-gradient px-2">{{
                                        lessExpensiveCard.artist }}</span>
                                </div>
                                <div>
                                    <UBadge
                                        class="text-wankil_blue-500 ring-wankil_blue-500 bg-wankil_blue-100/10 dark:bg-wankil_blue-100/10 dark:text-wankil_blue-100 dark:ring-wankil_blue-100"
                                        size="lg" variant="subtle">{{ lessExpensiveCard.season || "N/A" }}
                                    </UBadge>
                                </div>
                                <div class="text-xl md:text-4xl">
                                    {{ lessExpensiveCard.mediumPrice || "N/A" }} €
                                </div>
                            </div>
                            <div class="flex justify-between gap-4">
                                <div v-if="lessExpensiveCard?.adNumber > 1">
                                    {{ lessExpensiveCard?.adNumber }} annonces entre {{ lessExpensiveCard?.minPrice }}€
                                    et {{
                                    lessExpensiveCard.maxPrice
                                    }}€
                                </div>
                                <div v-else>
                                    1 annonce à {{ lessExpensiveCard?.minPrice }}€
                                </div>
                            </div>
                        </div>
                    </div>
                </TransitionGroup>
            </div>
        </div>
        <div
            class="w-full shadow-lg flex flex-col gap-4 md:gap-0 md:flex-row items-start md:items-center justify-between p-4 md:p-12 bg-cool-200 dark:bg-cool-600 rounded-3xl md:px-20">
            <div class="ads-total flex md:flex-col justify-center items-center gap-2 md:gap-0">
                <UIcon name="i-heroicons-shopping-cart text-xl md:text-5xl" />
                <span class="text-sm italic"><span class="text-xl not-italic font-semibold">{{ adsStats?.totalAds
                        }}</span>
                    annonces</span>
            </div>
            <div class="ads-good-scoring flex md:flex-col justify-center items-center gap-2 md:gap-0">
                <UIcon name="i-heroicons-check-badge text-xl md:text-5xl" />
                <span class="text-sm italic"><span class="text-xl not-italic font-semibold">{{
                        adsStats?.totalAdsWithGoodScoring
                        }}</span> annonces identifiées</span>
            </div>
            <div class="ads-inactive-value flex md:flex-col justify-center items-center gap-2 md:gap-0">
                <UIcon name="i-heroicons-banknotes text-xl md:text-5xl" />
                <span class="text-sm italic"><span class="text-xl not-italic font-semibold">{{
                        adsStats?.totalAdsValue?.toFixed(2) }}€</span> de marché total</span>
            </div>
            <div class="ads-active-value flex md:flex-col justify-center items-center gap-2 md:gap-0">
                <UIcon name="i-heroicons-credit-card text-xl md:text-5xl" />
                <span class="text-sm italic"><span class="text-xl not-italic font-semibold">{{
                        adsStats?.totalActiveAdsValue?.toFixed(2)
                        }}€</span> de valeur de marché actuel</span>
            </div>
        </div>
        <div class="flex flex-col lg:flex-row gap-4 md:gap-8 flex-1">
            <div class="lg:w-3/4 rounded-3xl flex flex-col justify-between">
                <div class="flex flex-col gap-4 w-full p-4 md:p-12 md:px-20">
                    <div class="text-3xl md:text-6xl uppercase font-extrabold">À propos</div>
                    <p>
                        Nous sommes actuellement deux développeurs à développer cette application. Elle est jeune et a probablement beaucoup de
                        défauts. Il
                        ne fait aucun doute
                        qu'elle gagnera en fiabilité avec le temps, mais je tiens à vous faire part de quelques détails
                        de
                        fonctionnements.
                    </p>
                    <p>
                        Tout d'abord, nous ne sommes pas en mesure d'identifier toutes les annonces de manière précise. Nous
                        ne considèrons
                        donc que
                        celles ayant un score d'identification au-delà d'un certain seuil. Le prix moyen est donc le
                        reflet des
                        annonces clairement identifiées.
                        Cela ne devrait pas fausser le prix des plus grosses cartes (étant vendues à l'unité) mais bien
                        celles des
                        petites, souvent vendues en lots.
                    </p>
                    <p>
                        Si vous avez la moindre remarque ou suggestion vous pouvez en faire part à la communauté sur
                        <ULink to="https://wankulmarket.sleekplan.app" target="_blank" active-class="text-primary"
                            inactive-class="text-wankil_blue-500 dark:text-wankil_blue-100 dark:text-gray-400 hover:text-wankil_blue-100 dark:hover:text-wankil_blue-500">
                            la page de Feedback !
                        </ULink>
                        Vous pouvez également vous tenir informé du contenu des mises à jour sur <ULink
                            to="https://wankulmarket.sleekplan.app/changelog" target="_blank"
                            active-class="text-primary"
                            inactive-class="text-wankil_blue-500 dark:text-wankil_blue-100 dark:text-gray-400 hover:text-wankil_blue-100 dark:hover:text-wankil_blue-500">
                            la page de Changelog !
                        </ULink>
                    </p>
                    <p>
                        N'oubliez pas que cette application est pensée pour la communauté Wankil, alors nous pouvons la
                        développer
                        tous ensemble ! Chaque remarque pourra y contribuer.
                    </p>
                </div>
            </div>
        </div>
        <div
            class="w-full flex-col shadow-lg flex items-center justify-between p-4 md:p-12 bg-cool-200 dark:bg-cool-600 rounded-3xl md:px-20 gap-8">
            <div class="flex flex-col gap-2 lg:gap-0 lg:flex-row w-full justify-between lg:items-center">
                <div class="flex flex-col lg:flex-row gap-2 lg:gap-8 lg:items-center">
                    <div class="text-3xl md:text-6xl font-extrabold uppercase">
                        Cartes rares
                    </div>
                    <div class="text-md">
                        <div>Ces cartes sont rares et se font rares sur le marché de l'occasion.</div>
                        <div>Elles pourraient être intéréssantes.</div>
                    </div>
                </div>
                <UButton size="xl" icon="i-heroicons-arrow-right" to="/cards" :prefetch="true"
                    class="bg-wankil_blue-500 dark:bg-wankil_blue-100 w-fit hover:bg-wankil_blue-100 dark:hover:bg-wankil_blue-500 text-white"
                    :ui="{ rounded: 'rounded-full' }" trailing>Parcourir les cartes</UButton>
            </div>
            <div class="flex flex-col md:flex-row flex-wrap justify-between w-full gap-4">
                <div class="flex flex-col gap-4" v-for="card in rareCards" :key="card?.id">
                    <div ref="element" class="image-tilt-wrapper rounded-xl relative">
                        <ULink class="flex justify-center" :to="`/cards/${card?.id}`">
                            <NuxtImg class="rounded-xl shadow-2xl w-full md:w-60"
                                :src="`${config.public.basePath}/api/media/wankuldex/${card.imageId}`"
                                alt="Carte wankul rare" srcset="" />
                        </ULink>
                    </div>
                    <div class="flex items-center justify-between gap-2">
                        <div class="flex flex-col w-full">
                            <div><span class="font-bold text-lg">{{ card?.title }}</span></div>
                            <div class="flex gap-2 justify-between items-center"><span>{{ card?.mean_price?.toFixed(2)
                                    }}€</span><span class="text-lg font-yellowtail artist-gradient px-2">{{
                                    card?.artist }}</span>
                            </div>
                        </div>
                        <UChip :text="card?.active_ad_number" size="2xl"
                            :ui="{ base: 'ring-0 bg-wankil_blue-100 dark:bg-wankil_blue-100' }">
                            <UButton :to="`/cards/${card?.id}`" icon="i-heroicons-shopping-cart"
                                class="bg-wankil_blue-500 dark:bg-wankil_blue-100" />
                        </UChip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.slide-fade-enter-active {
    transition: all 0.8s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>